import React, { useState } from "react";
import PropTypes from "prop-types";

import Layout from "../components/layout";
import SEO from "../components/seo";
import CategoryItem from "../components/categoryItem";
import CollectionItem from "../components/collectionItem";

import {
  Breadcrumbs,
  Button,
  Hero,
  IconBox,
  SecondaryHeader,
  SectionHeader,
  Link,
  FaqItem,
} from "../components/common";

import QuestionMark from "../assets/images/vectors/question-mark.svg";
import Subscription from "../components/subscription";
import { getStaticSEOImage } from "../helpers";

function CollectionsPage({
  pageContext: { data: collections, categories },
  location,
}) {
  if (!collections?.length || !categories?.length) {
    return null;
  }

  const [itemCount, setItemCount] = useState(15);
  const selectedCollections = collections.slice(0, itemCount);
  const title = "All Niche Collections";
  const description =
    "Explore our niche based collections curated for your specific needs. Use in presentations, social media promotions, marketing campaigns and other projects.";

  return (
    <Layout>
      <SEO
        keywords={[`vackground`, `pattern`, `background`, `design`]}
        title={title}
        description={description}
        image={getStaticSEOImage("collections")}
        path={location?.pathname}
      />

      <section className="md:mt-24 mt-12 mb-24">
        <Hero
          subTitle="Get Your Visual Needs"
          title={title}
          description={description}
        >
          <Breadcrumbs
            lists={[
              {
                title: "Home",
                to: "/",
              },
              {
                title: "All Niche Collections",
                to: "/collections",
              },
            ]}
          />
        </Hero>
      </section>
      <section className="md:mb-32 mb-24">
        <div className="dev-vg-g-product-section__items grid lg:grid-cols-3 md:grid-cols-2 grid-cols-1 gap-x-8 md:gap-y-16 gap-y-12">
          {selectedCollections?.map((collection, index) => (
            <CollectionItem key={index} collection={collection} />
          ))}
        </div>
        {collections.length > itemCount && (
          <div className="flex items-center justify-center md:mt-16 mt-12">
            <Button
              contentInOneLine
              onClick={() => setItemCount(itemCount + 15)}
              icon="vg-arrow-right"
              text="Load More Collections"
            />
          </div>
        )}
      </section>
      <section className="md:mb-40 mb-24">
        <div className="md:mb-16 mb-12">
          <SectionHeader
            art
            title={
              <>
                Explore
                <br />
                Without Any Boundaries
              </>
            }
            description="We aim to give more, to ensure you grow big. Get the best perks for your best money. Use our products unlimited times in unlimited projects by unlimited ways."
          />
        </div>
        <div className="grid lg:grid-cols-3 md:grid-cols-2 grid-cols-1 md:gap-8 gap-12">
          <IconBox icon="vg-infinity" title="Unlimited Use">
            Buy once, keep forever and use in unlimited projects.
          </IconBox>
          <IconBox icon="vg-file" title="Extended License">
            Use extensive ways but for a price of standard license.
          </IconBox>
          <IconBox
            className="col col-span-1 md:col-span-2 lg:col-span-1"
            icon="vg-circle-check"
            title="60-Day Refund"
          >
            Checkout with confidence. And get a piece of mind, for{" "}
            <Link to="/refund">60 days!</Link>
          </IconBox>
        </div>
      </section>
      <section className="lg:mb-40 mb-24">
        <div>
          <SecondaryHeader
            title={<>Transform Your Imagination into Reality</>}
            description={
              <>
                Creating a great looking design is hard, really hard. It
                requires years of experience. Even you know and have the
                experience, you will find tough times to add some extra elements
                which is not your type. We, as a designer, can not be expert in
                every section of design field. So we need additional design
                elements from other designers to fill the gap in our designs.
                <br />
                <br />
                Here we are, providing a wide range of theme{" "}
                <Link to="/categories">backgrounds</Link>,{" "}
                <Link to="/categories">surface patterns</Link>, decorative{" "}
                <Link to="/categories">elements</Link>,{" "}
                <Link to="/categories">textures</Link>, photo packs and more.
                You just have to design the basic stuff with your texts. All the
                other elements and backgrounds can be added from{" "}
                <Link to="/">Vackground</Link>
                .
                <br />
                <br />
                Explore our categories and collections to find suitable design
                assets. If not found, please{" "}
                <Link to="/contact">suggest us</Link> what should we make for
                you.
              </>
            }
          />
        </div>
      </section>
      <section className="lg:mb-32 mb-24">
        <div className="dev-vg-g-product-section__head mb-12">
          <SectionHeader
            title="Looking For Categories?"
            description="Categories have too many products to choose from. That’s why we gathered our design assets into niche collections. Go grab your desired asset now, it’s easier than ever."
          />
        </div>
        <div className="dev-vg-g-product-section__items grid lg:grid-cols-3 md:grid-cols-2 grid-cols-1 gap-x-8 md:gap-y-16 gap-y-12">
          {categories.map((category) => (
            <CategoryItem key={category.id} category={category} />
          ))}
        </div>
        <div className="md:mt-16 mt-12 flex items-center justify-center">
          <Button
            icon="vg-arrow-right"
            text="Explore Categories"
            to="/categories"
          />
        </div>
      </section>
      <section className="lg:mb-48 mb-40">
        <FaqItem
          title="Collection FAQs"
          artVector={QuestionMark}
          faqs={[
            {
              question: "What kind of graphic resources you make regularly?",
              answer: `Creating a great looking design is hard, really hard. It requires
            years of experience. Even you know and have the experience, you will
            find tough times to add some extra elements which is not your type.
            We, as a`,
            },
            {
              question:
                "Do your surface patterns can be repeatable (tile-able)?",
              answer: `Creating a great looking design is hard, really hard. It requires
            years of experience. Even you know and have the experience, you will
            find tough times to add some extra elements which is not your type.
            We, as a`,
            },
            {
              question: "Do your files backward Adobe software compatible?",
              answer: `Creating a great looking design is hard, really hard. It requires
            years of experience. Even you know and have the experience, you will
            find tough times to add some extra elements which is not your type.
            We, as a`,
            },
            {
              question: "How to edit vector files?",
              answer: `Creating a great looking design is hard, really hard. It requires
            years of experience. Even you know and have the experience, you will
            find tough times to add some extra elements which is not your type.
            We, as a`,
            },
            {
              question: "Can I use the design files in unlimited projects?",
              answer: `Creating a great looking design is hard, really hard. It requires
            years of experience. Even you know and have the experience, you will
            find tough times to add some extra elements which is not your type.
            We, as a`,
            },
            {
              question: "What kind of graphic resources you make regularly?",
              answer: `Creating a great looking design is hard, really hard. It requires
            years of experience. Even you know and have the experience, you will
            find tough times to add some extra elements which is not your type.
            We, as a`,
            },
            {
              question:
                "Can I use the free files in my design project for commercial print?",
              answer: `Creating a great looking design is hard, really hard. It requires
            years of experience. Even you know and have the experience, you will
            find tough times to add some extra elements which is not your type.
            We, as a`,
            },
            {
              question: "Can I use Apple Pay or Google Wallet?",
              answer: `Creating a great looking design is hard, really hard. It requires
            years of experience. Even you know and have the experience, you will
            find tough times to add some extra elements which is not your type.
            We, as a`,
            },
            {
              question:
                "I need a product right now ASAP, but would like to pay later, what should I do?",
              answer: `Creating a great looking design is hard, really hard. It requires
            years of experience. Even you know and have the experience, you will
            find tough times to add some extra elements which is not your type.
            We, as a`,
            },
            {
              question:
                "If I face any difficulties to work with the files, would you help?",
              answer: `Creating a great looking design is hard, really hard. It requires
            years of experience. Even you know and have the experience, you will
            find tough times to add some extra elements which is not your type.
            We, as a`,
            },
            {
              question: "I have a question to ask, how can I contact?",
              answer: `Creating a great looking design is hard, really hard. It requires
            years of experience. Even you know and have the experience, you will
            find tough times to add some extra elements which is not your type.
            We, as a`,
            },
          ]}
        />
      </section>
      <section className="lg:mb-32 mb-8">
        <Subscription />
      </section>
    </Layout>
  );
}

CollectionsPage.propTypes = {
  pageContext: PropTypes.object,
  location: PropTypes.object,
};

export default CollectionsPage;
